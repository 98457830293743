<template>
	<transition>
		<div ref="contentsWrap" class="contents-wrap" id="contentsWrap">
			<div class="contents-box">
				<div class="conts-row" v-if="formData.depth > 1">
					<div class="conts-box">
						<div class="conts-title">상위 부서</div>
						<div v-if="formData.depth > 1">
							{{ parentDeptDepth1.deptNm }}
						</div>
						<div v-if="formData.depth > 2">
							{{ parentDeptDepth2.deptNm }}
						</div>
						<div v-if="formData.depth > 3">
							{{ parentDeptDepth3.deptNm }}
						</div>
					</div>
				</div>
				<div class="conts-row">
					<div class="conts-box">
						<div class="conts-title">센터*</div>
              <DxSelectBox
                v-model="formData.tenantId"
                placeholder="센터 선택"
                :items="getTenantList"
                display-expr="tenantNm"
                value-expr="id"
                :show-clear-button="config.showClearButton"
                :styling-mode="config.stylingMode"
                :width="config.width"
              >
                <DxValidator>
                  <DxRequiredRule message="센터는 필수입니다." />
                </DxValidator>
              </DxSelectBox>
					</div>
				</div>

        <div class="conts-row">
					<div class="conts-box">
						<div class="conts-title">부서명*</div>
						<DxTextBox v-model="formData.deptNm" :styling-mode="config.stylingMode" :max-length="limitLength.deptNm" :width="config.width">
							<DxValidator>
								<DxRequiredRule message="부서명은 필수입니다." />
							</DxValidator>
						</DxTextBox>
						<div class="limitLength-box">
							<span>{{ formData.deptNm !== null ? formData.deptNm.length : 0 }}</span>/{{ limitLength.deptNm }}
						</div>
					</div>
				</div>

        <div class="conts-row">
          <div class="conts-box">
            <div class="conts-title">약어</div>
            <DxTextBox v-model="formData.abbrNm" :styling-mode="config.stylingMode" :width="config.width">
            </DxTextBox>
          </div>
        </div>

        <div class="conts-row">
          <div class="conts-box">
            <div class="conts-title">부서코드</div>
            <DxTextBox v-model="formData.deptCode" :styling-mode="config.stylingMode" :width="config.width">
            </DxTextBox>
          </div>
        </div>

				<div class="conts-row">
					<div class="conts-box">
						<div class="conts-title">사용여부</div>
						<div class="conts-input">
							<DxSwitch
								@value-changed="onChangedViewFl($event)"
								:value="getViewFl"
								switched-off-text="OFF"
								switched-on-text="ON"
							/>
						</div>
					</div>
				</div>

			</div>
		</div>
	</transition>
</template>

<script>
import { DxTextBox } from 'devextreme-vue/text-box';
import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
import DxSwitch from 'devextreme-vue/switch';
import { DxSelectBox } from 'devextreme-vue/select-box';

import { isSuccess } from "@/plugins/common-lib";

export default {
	components: {
		DxTextBox,
		DxValidator,
		DxRequiredRule,
		DxSwitch,
		DxSelectBox,
	},
	props: {
		contentData: Object,
		iconData: Array,
		deptList: Array,
	},
	watch: {},
	data() {
		return {
      config: {
        stylingMode: 'outlined',
        showClearButton: true,
        width: 350,
      },
			formData: {
				tenantId: null,
				parentId: null,
				depth: this.contentData.depth,
				deptNm: null,
				viewFl: 'Y',
        deptCode: null,
        abbrNm: null,
			},
			limitLength: {
				deptNm: 35,
			},
		};
	},
	computed: {
		/** @description: props 리스트에서 선택한 데이터 */
		getApiActionNm() {
			return this.contentData.apiActionNm;
		},
    /** @description: 테넌트 store 정보 가져오기 */
    getTenantList() {
      return this.$store.getters.getTenantList.filter(d => Number(d.viewCd) === this.$_getUsedCode.id);
    },
		/** @description: 사용여부 UI 설정 */
		getViewFl() {
			return this.formData.viewFl === 'Y';
		},
    /** @description: depth 별 부서 리스트 */
    getDeptListByDepth() {
      return this.contentData.deptList.filter(item => item.depth === this.contentData.depth);
    },
    /** @description: depth1 상위부서 데이터 */
    parentDeptDepth1() {
      if (this.depth2) {
        return this.contentData.deptList.find(d => d.id === this.depth2.parentId && d.depth === 1);
      } else {
        return this.contentData.deptList.find(d => d.id === this.formData.parentId);
      }
    },
    /** @description: depth2 상위부서 데이터 */
    parentDeptDepth2() {
      if (this.depth3) {
        return this.contentData.deptList.find(d => d.id === this.depth3.parentId && d.depth === 2);
      } else {
        return this.contentData.deptList.find(d => d.id === this.formData.parentId && d.depth === 2);
      }
    },
    /** @description: depth3 상위부서 데이터 */
		parentDeptDepth3() {
			if (this.formData.depth === 4) {
				return this.contentData.deptList.find(d => d.id === this.formData.parentId && d.depth === 3);
			} else {
				return null;
			}
		},
	},
	methods: {
		/** @description: 사용여부 변경 이벤트 */
		onChangedViewFl(e) {
			if(e.value) {
        this.formData.viewFl = 'Y';
      } else {
        this.formData.viewFl = 'N';
      }
		},
		/** @description : 라이프사이클 creaed시 호출되는 메서드 */
		createdData() {
			this.formData = { ...this.formData, ...this.contentData };
		},
		/** @description : 라이프사이클 mounted시 호출되는 메서드 */
		mountedData() {
			this.$_eventbus.$on('ModalRootDept:onSaveData', async (e, resolve) => {
				if (!e.validationGroup.validate().isValid) {
					return false;
				}

        //해당 depth의 deptOrd 최대값 구해서 순서 값 설정
        this.formData.deptOrd = Math.max(...this.getDeptListByDepth.map(item => item.deptOrd)) + 1;

				if(await this.$_Confirm(this.$_msgContents('COMMON.MESSAGE.CMN_CFM_SAVE', { defaultValue: '저장하시겠습니까?' }))) {
					const payload = {
						actionname: 'DEPT_LIST_UPDATE',
						data: {
              data: [this.formData],
            },
						loading: true,
					};

					const res = await this.CALL_API(payload);
          if(isSuccess(res)) {
            this.$_Toast(this.$_msgContents('COMMON.MESSAGE.CMN_SUC_SAVE', {defaultValue: '정상적으로 저장되었습니다.'}));
            resolve(200);
          }
				}
			});
		},
		/** @description : 라이프사이클 destroyed시 호출되는 메서드 */
		destroyedData() {
			this.$_eventbus.$off('ModalRootDept:onSaveData');
		},
	},
	created() {
		this.createdData();
	},
	mounted() {
		this.mountedData();
	},
	destroyed() {
		this.destroyedData();
	},
};
</script>

<style scoped>
.contents-title-box {
	height: 40px;
	position: relative;
	border-bottom: 1px solid #ccc;
	line-height: 40px;
}
.contents-title-box .contents-title {
	height: 40px;
	padding-left: 5px;
	display: inline-block;
	font-size: 0.9em;
}

.contents-box {
	width: 100%;
	padding: 20px 10px;
	background-color: #fff;
}

.conts-row:not(:last-child) {
	margin-bottom: 20px;
}
.conts-row .conts-box {
	display: inline-block;
}
.conts-row .conts-box > div {
	display: inline-block;
}
.conts-row .conts-box .conts-title {
	width: 120px;
	display: inline-block;
	vertical-align: top;
}
.conts-row .conts-box .conts-input {
	display: -webkit-inline-box;
}

.conts-row .conts-box .limitLength-box {
	margin-left: 5px;
	display: inline-block;
}
</style>
<style>
#modal_menu_config_menuicon .dx-texteditor-input {
	font-size: 15px;
	padding-top: 0px;
	padding-left: 2px;
}
</style>
