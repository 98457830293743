<template>
	<div class="container">
		<div class="page-sub-box tree-box tree-header mar_b10">
			<div class="head-btn-box01">
				<div class="head-btn-left">
					<DxButton text="최상위부서 추가" type="button" class="btn_XS default filled add1" :height="30" @click="onAddRootDept" />
					<DxDropDownButton
						:items="config.dropDownButton.items"
						:stylingMode="config.dropDownButton.stylingMode"
						:text="config.dropDownButton.text"
						:drop-down-options="{ width: 150 }"
						:element-attr="{ class: 'btn_XS white outlined' }"
						:height="config.dropDownButton.height"
						@item-click="onChangedDropDownButton"
					/>
				</div>
			</div>
			<div class="head-btn-box01 top-toolbar-box">
				<div class="head-btn-left">
					<DxButton
						template="<span class='mdi mdi-folder-open'></span>"
						type="button"
						hint="목록 펼치기"
						class="btn_XS white outlined"
						:height="30"
						@click="onOpenTree"
					/>
					<DxButton
						template="<span class='mdi mdi-folder'></span>"
						type="button"
						hint="목록 접기"
						class="btn_XS white outlined"
						:height="30"
						@click="onFoldTree"
					/>
					<DxButton
						template="<span class='mdi mdi-chevron-double-up'></span>"
						:height="30"
						type="button"
            hint="맨 위로 이동"
						class="btn_XS white outlined"
						@click="onSetOrder(0)"
					/>
					<DxButton
						template="<span class='mdi mdi-chevron-up'></span>"
						:height="30"
						type="button"
            hint="위로 이동"
						class="btn_XS white outlined"
						@click="onSetOrder(1)"
					/>
					<DxButton
						template="<span class='mdi mdi-chevron-down'></span>"
						:height="30"
						type="button"
            hint="아래로 이동"
						class="btn_XS white outlined"
						@click="onSetOrder(2)"
					/>
					<DxButton
						template="<span class='mdi mdi-chevron-double-down'></span>"
						:height="30"
						type="button"
            hint="맨 아래로 이동"
						class="btn_XS white outlined"
						@click="onSetOrder(3)"
					/>
					<DxButton template="순서 저장" :height="30" type="button" class="btn_XS white outlined" @click="onSaveSort" />
				</div>
				<div class="head-btn-right"></div>
			</div>
		</div>

    <div class="page-sub-box tree-box tree-contents pad_bo100">
			<div class="layout-cut-box clearfix tree-box00">
				<div class="layout-cut-left tree-box01 fl">
					<div class="treemenu-set">
						<!--트리메뉴 영역 정의-->
						<DxTreeList
							id="deptList"
							ref="deptList"
							:data-source="tree.deptList"
							:root-value="-1"
							:expanded-row-keys="tree.expandedRowKeys"
							:selected-row-keys="tree.selectedRowkeys"
							:focused-row-key="tree.focusedRowKey"
							:auto-expand-all="true"
							:show-column-headers="false"
							:show-row-lines="true"
							:show-column-lines="false"
							:show-borders="false"
							:column-auto-width="true"
							key-expr="id"
							parent-id-expr="parentId"
							@selection-changed="selectionChangedData"
							:on-row-click="onClickRow"
							:no-data-text="noDataText(tree.deptList.length)"
              :width="350"
							:height="750"
						>
							<DxSearchPanel
                placeholder=""
                :visible="true"
                :searchVisibleColumnsOnly="true"
                :width="300"
              />
							<DxSelection :recursive="tree.recursive" :mode="tree.mode" />
							<DxRowDragging
								:on-drag-change="onDragChange"
								:on-reorder="onReorder"
								:allow-drop-inside-item="tree.allowDropInsideItem"
								:allow-reordering="tree.allowReordering"
								:show-drag-icons="tree.showDragIcons"
							/>
							<DxScrolling mode="virtual" />

							<DxColumn caption="국세청" data-field="deptNm" cell-template="deptNmTemplate" :allow-sorting="false" />
							<template #deptNmTemplate="{ data }">
								{{ data.value }} {{ data.data.viewFl === 'N' ? '(사용 중지)' : '' }}
							</template>
						</DxTreeList>
					</div>
				</div>
				<div class="layout-cut-right tree-box02 fr">
					<template v-if="tree.focusedRowKey">
						<table class="table_form line-bin">
							<caption>
								<strong>운영 관리</strong>
							</caption>
							<colgroup>
								<col style="width:120px;" />
								<col style="width:auto;" />
							</colgroup>
							<thead class="sub_title_txt inner-header">
								<tr>
									<td colspan="2"><h2>부서 정보</h2></td>
								</tr>
							</thead>
							<tbody>
								<tr>
									<th scope="row"><label for="label1">상위 부서</label></th>
									<td id="label1">
										{{ nameTree }}
									</td>
								</tr>
								<tr>
									<th scope="row"><label for="label2">부서 아이디</label></th>
									<td>
										{{ formData.data.id }}
									</td>
								</tr>
								<tr>
									<th scope="row"><label for="label3">부서명*</label></th>
									<td>
										<DxTextBox
											v-model="formData.data.deptNm"
											:styling-mode="config.stylingMode"
											:max-length="formData.limitLength.deptNm"
											:width="350"
										>
											<DxValidator>
												<DxRequiredRule message="부서명은 필수입니다." />
											</DxValidator>
										</DxTextBox>
										{{ formData.data.deptNm && formData.data.deptNm !== null ? formData.data.deptNm.length : 0 }}/{{
											formData.limitLength.deptNm
										}}
									</td>
								</tr>
                <tr>
                  <th scope="row">
                    <label for="label5">약어</label>
                  </th>
                  <td>
                    <DxTextBox
                        id="label5"
                        v-model="formData.data.abbrNm"
                        :styling-mode="config.stylingMode"
                        :width="350"
                    >
                    </DxTextBox>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <label for="label4">부서코드</label>
                  </th>
                  <td>
                    <DxTextBox
                        id="label4"
                        v-model="formData.data.deptCode"
                        :styling-mode="config.stylingMode"
                        :width="350"
                    >
                    </DxTextBox>
                  </td>
                </tr>
								<tr>
									<th scope="row"><label for="label3">사용여부</label></th>
									<td>
										<DxSwitch @value-changed="onChangedViewFl($event)" :value="getViewFl" />
									</td>
								</tr>
								<tr v-if="formData.data.depth !== 4">
									<th scope="row"><label for="label3">하위부서</label></th>
									<td>
										<DxButton
											text="하위부서 추가"
											class="btn_XS white outlined add2"
											:height="30"
											@click="onAddSubDept"
										/>
									</td>
								</tr>
							</tbody>
						</table>
					</template>
					<template v-else>
						<div class="noformdata">부서를 선택하세요.</div>
					</template>
				</div>
			</div>
			<DxCheckBox class="checkbox" v-model="config.isViewFl" text="사용 중 부서만 보기" @value-changed="isUsedFlag"> </DxCheckBox>
		</div>

        <section v-if="tree.focusedRowKey" class="terms bottom-btn-box">
            <div class="page-sub-box">
                <h2 class="hidden">일반 버튼</h2>
                <div class="bottom-btn-wrap">
                    <DxButton
                        text="저 장"
                        class="btn_XS default filled txt_S medium"
                        :width="120"
                        :height="40"
                        :use-submit-behavior="true"
                        @click="onSaveFormData"
                    />
                    <DxButton
                        text="취 소"
                        class="btn_XS white filled txt_S medium"
                        :width="120"
                        :height="40"
                        @click="onCancelFormData"
                    />
                </div>
            </div>
        </section>

		<DxPopup
			:show-title="true"
			:title="modal.initData ? modal.initData.title : null"
			:min-width="modal.initData ? modal.initData.width : null"
			:width="modal.initData ? modal.initData.width : null"
			:min-height="modal.initData ? modal.initData.height : null"
			:height="modal.initData ? modal.initData.height : null"
			:drag-enabled="true"
			:resize-enabled="true"
			:show-close-button="true"
			:close-on-outside-click="false"
			:visible="modal.isOpened"
			@hiding="isOpenModal(false)"
		>
			<template #content>
				<div>
					<component
						:is="modal.currentComponent"
						:contentData="modal.contentData"
						v-model="modal.contentData"
					></component>
				</div>
			</template>

			<DxToolbarItem
				widget="dxButton"
				toolbar="bottom"
				location="center"
				:visible="
					modal.initData.hasOwnProperty('buttons')
						? modal.initData.buttons.hasOwnProperty('save')
							? modal.initData.buttons.hasOwnProperty('save')
							: !modal.initData.buttons.hasOwnProperty('save')
						: false
				"
				:options="{
					elementAttr: {
						class: 'default filled txt_S medium',
					},
					text: modal.initData.hasOwnProperty('buttons')
						? modal.initData.buttons.hasOwnProperty('save')
							? modal.initData.buttons.save.text
							: ''
						: '',
					//type: 'default',
					width: '120',
					height: '40',
					useSubmitBehavior: true,
					onClick: e => {
						onConfirmModal(e);
					},
				}"
			/>
			<DxToolbarItem
				widget="dxButton"
				toolbar="bottom"
				location="center"
				:visible="
					modal.initData.hasOwnProperty('buttons')
						? modal.initData.buttons.hasOwnProperty('cancel')
							? modal.initData.buttons.hasOwnProperty('cancel')
							: !modal.initData.buttons.hasOwnProperty('cancel')
						: false
				"
				:options="{
					elementAttr: {
						class: 'white filled txt_S medium',
					},
					text: modal.initData.hasOwnProperty('buttons')
						? modal.initData.buttons.hasOwnProperty('cancel')
							? modal.initData.buttons.cancel.text
							: ''
						: '',
					width: '120',
					height: '40',
					onClick: () => {
						isOpenModal(false);
					},
				}"
			/>
		</DxPopup>
	</div>
</template>

<script>
import ModalRootDept from '@/pages/organize/dept/modal-root-dept';
import ModalUploadDept from '@/pages/organize/dept/modal-upload-dept';
import ModalUpdateSelectedDept from '@/pages/organize/dept/modal-update-selected-dept';
import ModalUpdateEqualDept from '@/pages/organize/dept/modal-update-equal-dept';
import ModalUpdateOrganOrder from '@/pages/organize/dept/modal-update-organ-order';

import { DxTreeList, DxSearchPanel, DxSelection, DxColumn, DxRowDragging, DxScrolling } from 'devextreme-vue/tree-list';
import { DxButton } from 'devextreme-vue/button';
import { DxTextBox } from 'devextreme-vue/text-box';
import DxSwitch from 'devextreme-vue/switch';
import { DxCheckBox } from 'devextreme-vue/check-box';
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
import { DxSelectBox } from 'devextreme-vue/select-box';
import DxDropDownButton from 'devextreme-vue/drop-down-button';

import { isSuccess, setGridSingleSelection, findChildrenIdsById } from "@/plugins/common-lib";


export default {
	components: {
		ModalRootDept,
		ModalUploadDept,
		ModalUpdateSelectedDept,
		ModalUpdateEqualDept,
		ModalUpdateOrganOrder,

		DxTreeList,
		DxSearchPanel,
		DxSelection,
		DxColumn,
		DxRowDragging,
		DxScrolling,
		DxButton,
		DxTextBox,
		DxSwitch,
		DxCheckBox,
		DxPopup,
		DxToolbarItem,
		DxValidator,
		DxRequiredRule,
		DxSelectBox,
		DxDropDownButton,
	},
	props: {},
	watch: {},
	data() {
		return {
			config: {
				pageSetting: {
					config: {},
					formData: {},
				},
				stylingMode: 'outlined', //[outlined, filled, underlined]
				isViewFl: true,
				dropDownButton: {
					items: ['선택부서명 일괄변경', '직제순서 일괄변경'],
					text: '일괄 변경',
					showArrowIcon: false,
					stylingMode: 'text',
					//width: '100',
					height: '30',
				},
			},
			modal: {
				isOpened: false,
				currentComponent: null,
				initData: {},
				contentData: null,
			},
			tree: {
				originDeptList: [],
				deptList: [],
                sortList: [],
				expandedRowKeys: [],
				selectedRowkeys: [],
				focusedRowKey: null,
				selectedRowsData: [],
				recursive: false,
				mode: 'multiple',
				selectionMode: 'all', // 'all' or 'excludeRecursive' or 'leavesOnly'
				allowDropInsideItem: false,
				allowReordering: true,
				showDragIcons: true,
				selectedData: null,
			},
			formData: {
				data: {},
				limitLength: {
					deptNm: 35,
				},
				iconData: [],
			},
		};
	},
	computed: {
		/** @description: pageData -> pageSetting apiActionNm 가져오기 */
		getApiActionNm() {
			return this.config.pageSetting.config ? this.config.pageSetting.config.apiActionNm : null;
		},
		/** @description: 사용여부 */
		getViewFl() {
			return this.formData.data.viewFl === 'Y';
		},
		/** @description: 상위부서 값 가져오기 */
		nameTree() {
			let isCheckLineBar = this.formData.data.nameTree.includes('|');
			let topDeptTree = '-';
			if (isCheckLineBar) {
				const lastIndex = this.formData.data.nameTree.lastIndexOf('|');
				topDeptTree = this.formData.data.nameTree.substring(0, lastIndex).replaceAll('|', ' > ');
			}
			return topDeptTree;
		},
	},
	methods: {
		/** @description: 팝업 오픈시 메서드 */
		onOpenModal(componentNm, componentInitData, data) {
			this.modal.currentComponent = componentNm; //set dynamic component name in modal body slot
			this.modal.initData = componentInitData; //set init modal templet
			this.modal.contentData = data;

			if (this.modal.initData.name === 'subDept') {
				if (!this.tree.focusedRowKey) {
					return this.$_Msg(this.$_msgContents('REQUIRED_PARENT_DEPT'));
				}

				if (data.deptDepth > 4) {
					return this.$_Msg(this.$_msgContents('CHECK_DEPT_DEPTH'));
				}
			}

			this.isOpenModal(true);
		},
		/** @description: 팝업 오픈 체크 메서드 */
		isOpenModal(data) {
			this.modal.isOpened = data;
			if (!data) {
				this.modal.currentComponent = null;
				this.modal.initData = {};
			}
		},
		/** @description: 팝업 확인 버튼 이벤트 */
		async onConfirmModal(e) {
			//해당 모달 컴포넌트에서 데이터 저장
			const promise = new Promise((resolve, reject) => {
				this.$_eventbus.$emit(`${this.modal.currentComponent}:onSaveData`, e, resolve, reject);
			});

			promise
				.then(res => {
					if (res === 200) {
						this.initPage(); //페이지 초기화
					} else {
						this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
					}
				})
				.catch(() => {
					// handle error
					this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
				});
		},
		/** @description: 최상위 부서 추가 버튼 클릭 이벤트 */
		onAddRootDept() {
			this.onOpenModal(
				'ModalRootDept',
				{
					name: 'rootDept',
					title: this.$_msgContents('COMPONENTS.ADD', { defaultValue: '추가' }),
					buttons: {
						save: {
              text: this.$_msgContents('COMPONENTS.SAVE', { defaultValue: '저장' })
            },
						cancel: {
              text: this.$_msgContents('COMPONENTS.CANCEL', { defaultValue: '취소' })
            },
					},
					width: 600,
					height: 450,
				},
				{
					id: null,
					depth: 1,
          deptList: this.tree.originDeptList,
					apiActionNm: this.config.pageSetting.config.apiActionNm,
				},
			);
		},
		/** @description: 하위 부서 추가 버튼 클릭 이벤트 */
		onAddSubDept() {
			this.onOpenModal(
				'ModalRootDept',
				{
					name: 'subDept',
					title: '하위부서 등록',
					buttons: {
						save: {
              text: this.$_msgContents('COMPONENTS.SAVE', { defaultValue: '저장' })
            },
						cancel: {
              text: this.$_msgContents('COMPONENTS.CANCEL', { defaultValue: '취소' })
            },
					},
					width: 600,
					height: 450,
				},
				{
					id: null,
					depth: this.formData.data.depth ? this.formData.data.depth + 1 : null,
					tenantId: this.formData.data.tenantId,
					parentId: this.formData.data.id,
          deptList: this.tree.originDeptList,
					apiActionNm: this.config.pageSetting.config.apiActionNm,
				},
			);
		},
		/** @description: 일괄변경 드롭다운 버튼 선택시 이벤트 */
		onChangedDropDownButton(e) {
			if (e.itemData === this.config.dropDownButton.items[0]) {
				//선택부서명 일괄 변경
				this.onUpdateSelectedDept();
			} else if (e.itemData === this.config.dropDownButton.items[1]) {
				//직제순서 일괄변경
				this.onUpdateOrganOrder();
			}
		},
		/** @description: 선택부서명 일괄변경 */
		onUpdateSelectedDept() {
			const selectedRowsData = this.tree.selectedRowsData;

			if (selectedRowsData.length === 0) {
				return this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
			}

			this.onOpenModal(
				'ModalUpdateSelectedDept',
				{
					title: '선택부서명 일괄변경',
					buttons: {
            save: {
              text: this.$_msgContents('COMPONENTS.SAVE', { defaultValue: '저장' })
            },
            cancel: {
              text: this.$_msgContents('COMPONENTS.CANCEL', { defaultValue: '취소' })
            },
					},
					width: 600,
					height: 550,
				},
				{
					selectedRowsData: selectedRowsData,
					apiActionNm: this.config.pageSetting.config.apiActionNm,
				},
			);
		},
		/** @description: 동일부서명 일괄변경 */
		onUpdateEqualDept() {
			this.onOpenModal(
				'ModalUpdateEqualDept',
				{
					title: '동일부서명 일괄변경',
					buttons: {
            save: {
              text: this.$_msgContents('COMPONENTS.SAVE', { defaultValue: '저장' })
            },
            cancel: {
              text: this.$_msgContents('COMPONENTS.CANCEL', { defaultValue: '취소' })
            },
					},
					width: 600,
					height: 300,
				},
				{
					deptList: this.tree.deptList,
				},
			);
		},
		/** @description: 직제순서 일괄변경 */
		onUpdateOrganOrder() {
			this.onOpenModal(
				'ModalUpdateOrganOrder',
				{
					title: '직제순서 일괄변경',
					buttons: {
            save: {
              text: this.$_msgContents('COMPONENTS.SAVE', { defaultValue: '저장' })
            },
            cancel: {
              text: this.$_msgContents('COMPONENTS.CANCEL', { defaultValue: '취소' })
            },
					},
					width: 1000,
					height: 620,
				},
				{
					apiActionNm: this.config.pageSetting.config.apiActionNm,
				},
			);
		},
		/** @description: 트리 펼치기 */
		onOpenTree() {
			const rootIds = this.tree.deptList.map(d => {
				return d.id;
			});
			this.tree.expandedRowKeys = rootIds;
		},
		/** @description: 트리 접기 */
		onFoldTree() {
			this.tree.expandedRowKeys = [];
		},
		/** @description: 사용중인 부서만 보기 체크 */
		isUsedFlag(e) {
			if (e.value) {
				this.tree.deptList = this.tree.deptList.filter(d => d.viewFl === 'Y');
			} else {
				this.tree.deptList = this.tree.originDeptList;
			}
		},
		/** @description: 저장 메서드 */
		onSaveFormData(e) {
			if (!this.tree.focusedRowKey) {
				return this.$_Msg(this.$_msgContents('REQUIRED_SELECT_DEPT'));
			}

			if (!e.validationGroup.validate().isValid) {
				return false;
			}

			this.updateData([this.formData.data]);
		},
		/** @description: 저장 메서드 */
		async updateData(dataList) {
			const data = dataList;

			const payload = {
				actionname: 'DEPT_LIST_UPDATE',
				data: { data: data },
				loading: true,
			};

			if(await this.$_Confirm(this.$_msgContents('COMMON.MESSAGE.CMN_CFM_SAVE', { defaultValue: '저장하시겠습니까?' }))) {
				const res = await this.CALL_API(payload);
        if(isSuccess(res)) {
          this.isOpenModal(false);
          this.$_Toast(this.$_msgContents('COMMON.MESSAGE.CMN_SUC_SAVE', {defaultValue: '정상적으로 저장되었습니다.'}));
          this.initPage();
        }
			}
		},
		/** @description: 사용여부 이벤트 */
		onChangedViewFl(e) {
			if (e.value) {
        this.formData.data.viewFl = 'Y';
      } else {
        this.formData.data.viewFl = 'N';
      }
		},
		/** @description: 순서 설정 이벤트 */
    onSetOrder(type) {
      const selectedRowsData = this.tree.selectedRowsData;
      if (selectedRowsData.length !== 1) {
        return this.$_Msg(this.$_msgContents('CMN_ONE_SELECT'));
      }

      const selectedRowData = selectedRowsData[0];
      const item = this.tree.deptList.find(d => d.id === selectedRowData.id);
      if (!item) return;

      const itemIndex = this.tree.deptList.indexOf(item);
      const targetIndex = this.getTargetIndex(item, type);

      if (targetIndex !== -1) {
        this.moveItem(itemIndex, targetIndex);
        // 순서 처리 및 세팅
        this.setSort(this.tree.deptList, selectedRowData);
      }
    },

    getTargetIndex(item, type) {
      const siblings = this.tree.deptList.filter(d => d.depth === item.depth && d.parentId === item.parentId);
      const currentIndex = siblings.indexOf(item);

      switch (type) {
        case 0:
          return 0; // 맨 앞으로
        case 1:
          return Math.max(0, currentIndex - 1); // 한 칸 앞으로
        case 2:
          return Math.min(siblings.length - 1, currentIndex + 1); // 한 칸 뒤로
        case 3:
          return siblings.length - 1; // 맨 뒤로
        default:
          return -1; // 유효하지 않은 타입
      }
    },

    moveItem(fromIndex, toIndex) {
      if (fromIndex === toIndex) return;

      const item = this.tree.deptList.splice(fromIndex, 1)[0];
      this.tree.deptList.splice(toIndex, 0, item);
    },
		/** @description: 드래그 이벤트 */
		onDragChange(e) {
			this.tree.selectedRowkeys = [];
			let visibleRows = e.component.getVisibleRows(),
				sourceNode = e.component.getNodeByKey(e.itemData.id),
				targetNode = visibleRows[e.toIndex].node;
			while (targetNode && targetNode.data) {
				if (targetNode.data.id === sourceNode.data.id) {
					e.cancel = true;
					break;
				}
				targetNode = targetNode.parent;
			}
		},
		/** @description: 드래그 순서 변경 이벤트  */
		onReorder(e) {
			let visibleRows = e.component.getVisibleRows(),
				sourceData = e.itemData,
				targetData = visibleRows[e.toIndex].node.data,
				deptList = [...this.tree.deptList];
			if (e.dropInsideItem) {
				e.itemData.Head_ID = targetData.id;
				e.component.refresh();
			} else {
				let sourceIndex = deptList.indexOf(sourceData),
					targetIndex = deptList.indexOf(targetData);

				if (sourceData.Head_ID !== targetData.Head_ID) {
					sourceData.Head_ID = targetData.Head_ID;
					if (e.toIndex > e.fromIndex) {
						targetIndex++;
					}
				}

				deptList.splice(sourceIndex, 1);
				deptList.splice(targetIndex, 0, sourceData);
				this.tree.deptList = deptList;

        //순서 처리 및 세팅
        this.setSort(deptList, sourceData);
			}
		},
    /** @description: 순서 처리 및 세팅 */
    setSort(list, targetData) {
      // 리스트를 복제하지 않고 직접 수정
      const updatedSortList = list
          .filter(d => d.parentId === targetData.parentId)
          .reduce((acc, item, index) => {
            // 새 인덱스 설정
            item.index = index + 1;
            // sortList에서 현재 아이템의 인덱스 찾기
            const sortListIndex = this.tree.sortList.findIndex(el => el.id === item.id);
            if (sortListIndex !== -1) {
              // 기존 아이템 업데이트
              this.tree.sortList[sortListIndex] = item;
            } else {
              // 새 아이템 추가
              acc.push(item);
            }
            return acc;
          }, []);

      // 새로운 아이템들을 sortList에 추가
      this.tree.sortList.push(...updatedSortList);
    },
    /** @description: 순서 저장 메서드 */
    onSaveSort() {
      //변경된 순서 부서 데이터의 deptOrd 설정
      this.tree.sortList.forEach(item => {
        item.deptOrd = item.index;
      });
      this.updateData(this.tree.sortList);
    },
		/** @description: 트리메뉴 변경 이벤트 */
		selectionChangedData(e) {
			const selectedData = e.component.getSelectedRowsData(this.tree.selectionMode);
			this.tree.selectedRowsData = selectedData;
		},
		/** @description : 트리 로우 데이터 클릭 이벤트 */
		onClickRow(e) {
			//단일 선택 색상 설정
			setGridSingleSelection(e);

			const rowData = e.data;

			if(rowData) {
				this.formData.data = rowData;
				this.tree.focusedRowKey = rowData.id;
			}
		},
		/** @description: 부서 데이터 조회 */
		async selectDeptList(params) {
			const paramsData = {
        sort: '+depth,+deptOrd',
        pagesize: 100000,
        ...params
      };
			const payload = {
				actionname: 'DEPT_LIST_ALL_VIEW',
				data: { params: paramsData },
				loading: true,
			};

			const res = await this.CALL_API(payload);
      if( isSuccess(res) ) {
        //기존 테이블과 view 테이블의 PK 컬럼명이 달라 id로 맞춰줌
        const data = res.data.data.map(d => {
          const id = d.deptId;
          return { ...d, id };
        });
        this.tree.originDeptList = data; //all Data
        this.tree.deptList = data.filter(d => d.viewFl === 'Y'); //view filter data
      }
		},
		/** @description: 취소 버튼 클릭 이벤트 */
		onCancelFormData() {
			this.initPage(); //페이지 초기화
		},
		/** @description: 페이지 초기화 */
		initPage() {
			this.clearData(); //데이터 초기화
			this.selectDeptList(); //트리리스트 데이터 조회
			this.refreshTreeList(); //트리리스트 refresh
			this.isOpenModal(false); //팝업 close
		},
		/** @description: 데이터 초기화 */
		clearData() {
			this.tree.focusedRowKey = null;
			this.tree.selectedRowkeys = [];
			this.formData.data = {};
		},
		/** @description: treeList refresh 이벤트 */
		refreshTreeList() {
			if (this.$refs.deptList) {
				this.$refs.deptList.instance.refresh();
				this.$refs.deptList.instance.deselectAll(); //선택 해제
			}
		},
		/** @description: 삭제 메서드 */
		async onDeleteData(data) {
			const childrenArr = findChildrenIdsById([data.row.node], data.row.node.key);
      const deletedIds = [childrenArr.id, ...childrenArr.childIds].map(d => {
				return { id: d };
			});

			let msgContents = this.$_msgContents('COMMON.MESSAGE.CMN_CFM_DELETE', { defaultValue: '삭제하시겠습니까?' });
			if (data.row.node.children.length > 0) {
				msgContents = `삭제시 하위부서정보까지 삭제됩니다.<br/>${msgContents}`;
			}

			if (await this.$_Confirm(msgContents)) {
				await this.callDeleteApi(deletedIds);
			}
		},
		/** @description: 삭제 API 호출 */
		async callDeleteApi(deletedIds) {
			const payload = {
				actionname: 'DEPT_LIST_DELETE',
				data: { data: { data: deletedIds } },
				loading: true,
			};

			const res = await this.CALL_API(payload);
      if(isSuccess(res)) {
        this.$_Toast(this.$_msgContents('CMN_SUC_DELETE'));
        this.initPage(); //페이지 초기화
      }
		},
		/** @description: 부서 데이터 없을 경우 출력 메서드 */
		noDataText(length) {
			if (length === 0) {
				return '추가된 부서가 없습니다.';
			}
		},
		/** @description: 라이프 사이클 created시 호출되는 메서드 */
		createdData() {
			this.$_setPageSettingConfig();
			this.initPage(); //페이지 초기화
		},
	},
	created() {
		this.createdData();
	},
	mounted() {},
};
</script>
<style scoped>
.top-toolbar-box {
	margin: 0;
}
</style>
<style>
/** 트리리스트 검색 패널 관련 */
#deptList .dx-toolbar .dx-toolbar-after {
	margin: 0 auto;
	padding-left: 0;
	left: 0;
	right: 0;
}
#deptList .dx-placeholder {
	padding-left: 20px;
}
#deptList .dx-treelist-search-panel {
	margin: 0;
}
</style>
