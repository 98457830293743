<template>
	<transition>
		<div ref="contentsWrap" class="contents-wrap" id="contentsWrap">
			<div class="contents-box">
				<table class="table_form line-bin">
					<caption>
						<strong>동일부서명 일괄변경</strong>
					</caption>
					<colgroup>
						<col style="width:130px;" />
						<col style="width:auto;" />
					</colgroup>

					<thead class="sub_title_txt">
					</thead>

					<tbody>
						<tr>
							<th scope="row">
								<label for="label01">
									찾을 부서명
									<span class="icon_require">필수항목</span>
								</label>
							</th>
							<td>
								<DxSelectBox
									placeholder="검색할 부서명"
									v-model="formData.deptNm"
									:data-source="config.selectDeptList"
									search-mode="contains"
									search-expr="deptNm"
									:acceptCustomValue="false"
									:min-search-length="0"
									:search-enabled="true"
									:show-data-before-search="false"
									display-expr="deptNm"
									value-expr="deptNm"
									item-template="item"
									:styling-mode="config.stylingMode"
									:width="350"
									:dropDownOptions="{ height: 200 }"
									:showClearButton="true"
									@enter-key="onChangedSelectDeptNm"
								>
									<template #item="{ data }"> {{ data.deptNm }}({{ data.count }}) </template>
									<DxValidator>
										<DxRequiredRule message="찾을 부서명은 필수입니다." />
									</DxValidator>
								</DxSelectBox>
							</td>
						</tr>
						<tr>
							<th scope="row">
								<label for="label01">
									바꿀 부서명
									<span class="icon_require">필수항목</span>
								</label>
							</th>
							<td>
								<DxTextBox
									v-model="formData.updateDeptNm"
									:styling-mode="config.stylingMode"
									:max-length="limitNumberTexts.maxLengths.deptNm"
									:width="350"
								>
									<DxValidator>
										<DxRequiredRule message="바꿀 부서명은 필수입니다." />
									</DxValidator>
								</DxTextBox>
								{{
									limitNumberTexts.textLengths.updateDeptNm
										? limitNumberTexts.textLengths.updateDeptNm
										: formData.updateDeptNm
										? formData.updateDeptNm.length
										: 0
								}}/{{ limitNumberTexts.maxLengths.deptNm }}자
							</td>
						</tr>
					</tbody>
				</table>
			</div>

			<div class="container-bottom-box">
				찾을 부서명과 동일한 명칭의 부서를 모두 찾은 후, 바꿀 부서명으로 일괄 변경합니다.
			</div>
		</div>
	</transition>
</template>

<script>
import { DxTextBox } from 'devextreme-vue/text-box';
import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
import { DxSelectBox } from 'devextreme-vue/select-box';

import { isSuccess } from "@/plugins/common-lib";

export default {
	components: {
		DxTextBox,
		DxValidator,
		DxRequiredRule,
		DxSelectBox,
	},
	props: {
		contentData: Object,
		iconData: Array,
		deptList: Array,
	},
	watch: {
	},
	data() {
		return {
			config: {
				stylingMode: 'outlined',
				selectDeptList: null,
			},
			formData: {
				deptNm: null,
				updateDeptNm: null,
			},
			limitNumberTexts: {
				textLengths: {},
				maxLengths: {
					deptNm: 35,
				},
			},
		};
	},
	computed: {
		/** @description: props 리스트에서 선택한 데이터 */
		getApiActionNm() {
			return this.contentData.apiActionNm;
		},
	},
	methods: {
		/** @description: 찾을 부서명 selectbox 변경 이벤트 */
		async onChangedSelectDeptNm(e) {
			const value = e.component.option('text');
			if (value.length >= 2) {
				const paramsData = { deptNm: value };
				const payload = {
					actionname: 'DEPT_LIST_ALL_DEPTNM',
					data: { params: paramsData },
					loading: false,
				};

				const res = await this.CALL_API(payload);
        if(isSuccess(res)) {
            //기존 테이블과 view 테이블의 PK 컬럼명이 달라 id로 맞춰줌
            this.config.selectDeptList = res.data.data.map(d => {
              const id = d.deptId;
              return { ...d, id };
            });
        }
			}
		},
		/** @description : 라이프사이클 creaed시 호출되는 메서드 */
		createdData() {
			this.formData = { ...this.formData, ...this.contentData };
		},
		/** @description : 라이프사이클 mounted시 호출되는 메서드 */
		mountedData() {
			this.$_eventbus.$on('ModalUpdateEqualDept:onSaveData', async (e, resolve) => {
				if (!e.validationGroup.validate().isValid) {
					return false;
				}

				const data = {
					data: {
						deptNm: this.formData.deptNm,
						updateDeptNm: this.formData.updateDeptNm,
					},
				};

				if (await this.$_Confirm('부서명을 일괄 변경 하시겠습니까?')) {
					const payload = {
						actionname: 'DEPT_LIST_UPDATE_DEPTNM',
						data: data,
						loading: true,
					};
					const res = await this.CALL_API(payload);
          if( isSuccess(res) ) {
              this.$_Toast('부서명이 일괄 변경되었습니다.');
              resolve(200);
          }
				}
			});
		},
		/** @description : 라이프사이클 destroyed시 호출되는 메서드 */
		destroyedData() {
			this.$_eventbus.$off('ModalUpdateEqualDept:onSaveData');
		},
	},
	created() {
		this.createdData();
	},
	mounted() {
		this.mountedData();
	},
	destroyed() {
		this.destroyedData();
	},
};
</script>

<style scoped>
.contents-title-box {
	height: 40px;
	position: relative;
	border-bottom: 1px solid #ccc;
	line-height: 40px;
}
.contents-title-box .contents-title {
	height: 40px;
	padding-left: 5px;
	display: inline-block;
	font-size: 0.9em;
}

.contents-box {
	width: 100%;
	background-color: #fff;
}
.contents-box .table_form td {
	padding: 10px 0;
}

.conts-row:not(:last-child) {
	margin-bottom: 20px;
}
.conts-row .conts-box {
	display: inline-block;
}
.conts-row .conts-box > div {
	display: inline-block;
}
.conts-row .conts-box .conts-title {
	width: 120px;
	display: inline-block;
}
.conts-row .conts-box .conts-input {
	display: -webkit-inline-box;
}

.container-bottom-box {
	margin-top: 20px;
	font-size: 0.9em;
	text-align: center;
}
.container-bottom-box .length-txt {
	color: tomato;
}
</style>
<style>
#modal_menu_config_menuicon .dx-texteditor-input {
	font-size: 15px;
	padding-top: 0px;
	padding-left: 2px;
}
</style>
